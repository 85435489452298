<template>
  <div id="app1" class="app-container" :key="key">
    <el-tabs v-model="activeTab" @tab-click="handleClick">
      <el-tab-pane lazy label="已分配" name="1">
        <tab1 :activeTab="activeTab" @update="forceUpdate" />
      </el-tab-pane>
      <el-tab-pane lazy label="未分配" name="0">
        <tab1 :activeTab="activeTab" @update="forceUpdate" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Tab1 from './tab1'

export default {
  components: { Tab1 },
  data() {
    return {
      activeTab: this.$route.query.toshop || '1',
      key: 0,
    }
  },
  mounted() {
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!from.path.includes('box/query')) {
        from.name && vm.forceUpdate()
        vm.activeTab = '1'
      }
    })
  },
  methods: {
    handleClick(tab) {
      this.activeTab = tab.name
      this.$router.push({ query: { ...this.$route.query, toshop: tab.name } })
    },
    forceUpdate() {
      this.key ++
    },
  },
  created() {
    // this.$router.push({ query: { toshop: this.activeTab, ...this.$route.query } })
  },
}
</script>

<style lang="less" scoped>
.app-container {
  padding-top: 12px;
}
/deep/ .el-tabs__content {
  min-height: 80vh;
}
</style>