<template>
  <div>
    <el-dialog
      title="运行系统"
      class="dialog"
      :visible="dialogVisible"
      width="300px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="handleCancel"
    >
      <div class="main">
        <div class="flow-box">
          <span>客流：</span>
          <el-switch v-model="flowValue"></el-switch>
        </div>

        <div class="security-box">
          <span>安防：</span>
          <el-switch v-model="securityValue"></el-switch>
        </div>

        <div class="des">操作生效大概有3分钟延迟</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button :disabled="isDisabled" type="primary" :loading="isSubmitting" @click="handleConfirm">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateBoxRunSystem } from 'src/api/legacy'

export default {
  data() {
    return {
      isDisabled: false,
      isSubmitting: false,

      flowValue: false,
      securityValue: false,
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    boxId: {
      type: String,
      default: null,
    },
    flow: {
      type: Boolean,
      default: false,
    },
    security: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  watch: {
    dialogVisible(val) {
      this.flowValue = this.flow
      this.securityValue = this.security
    },
  },
  methods: {
    handleCancel() {
      this.$emit('close')
    },
    async handleConfirm() {
      const params = {
        boxId: this.boxId,
        passFlowEnable: this.flowValue ? 1 : 0,
        safetyEnable: this.securityValue ? 1 : 0,
      }
      const res = await updateBoxRunSystem(params)
      if (res.status === 0) {
        this.$message({
          type: 'success',
          message: '修改成功',
        })
        this.$emit('update')
        this.handleCancel()
      }
    },
  },
}
</script>

<style scoped lang="less">
.dialog {
  .main {
    text-align: center;
  }

  .flow-box,
  .security-box,
  .des {
    margin-bottom: 20px;
  }
  .des {
    color: #ccc;
  }
}
</style>
