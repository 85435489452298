<template>
  <div>
    <div class="tableStatus" @click="handleOpen">
      <div class="flow-box">
        <i :class="['status', { open: flow }]"></i>
        客流
      </div>

      <div>
        <i :class="['status', { open: security }]"></i>
        安防
      </div>
    </div>

    <dialog-box
      :dialogVisible="dialogVisible"
      :hasSolution="true"
      :boxId="data.boxId"
      :flow="formatType(data.passFlowEnable)"
      :security="formatType(data.safetyEnable)"
      @update="update"
      @confirm="handleConfirm"
      @close="handleClose"
    />
  </div>
</template>

<script>
import DialogBox from './dialog.vue'

export default {
  data() {
    return {
      flow: false,
      security: false,

      dialogVisible: false,
    }
  },
  props: {
    data: Object,
    canEdit: Boolean
  },
  components: { DialogBox },
  mounted() {
    const data = this.data.boxSubType
    if (data == 3) {
      this.flow = true
      this.security = true
    } else if (data == 1) {
      this.flow = true
    } else if (data == 2) {
      this.security = true
    }
  },
  methods: {
    handleOpen() {
      if (!this.canEdit) return
      this.dialogVisible = true
    },
    handleConfirm() {
      this.dialogVisible = false
    },
    handleClose() {
      this.dialogVisible = false
    },
    update() {
      this.$emit('update')
    },
    formatType(val) {
      return val == 1
    }
  },
}
</script>

<style scoped lang="less">
.tableStatus {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .flow-box {
    margin-right: 5px;
  }

  .status {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 0px;
    background-color: #e65c5c;
  }
  .open {
    background-color: #52cc8f;
  }
  .unknow {
    background-color: #909399;
  }
  .close {
    background-color: #e65c5c;
  }
}
</style>
